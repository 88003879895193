import { getStatusLabel } from '@/support/ReportStatus';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { SidemenuItem } from '@/components/sidemenu/Sidemenu';

@Component<Expert>({})
export default class Expert extends Vue {
  public $pageTitle = 'Dossier statistieken';

  protected isLoading = true;

  protected items: SidemenuItem[] = [
    {
      title: 'Dashboard',
      link: '/bureau',
      icon: {
        key: 'dashboard',
      },
    },
    {
      title: 'Mededelingen',
      link: '/bureau/mededelingen',
      icon: {
        key: 'notification_important',
      },
    },
  ];

  protected regulierDeskundige: SidemenuItem[] = [
    {
      title: getStatusLabel('opname_in_progress'),
      link: '/bureau/reports?status=opname_in_progress',
      icon: {
        key: 'camera_alt',
      },
    },
    {
      title: getStatusLabel('opname_rejected'),
      link: '/bureau/reports?status=opname_rejected',
      icon: {
        key: 'flip_camera_ios',
      },
    },
    {
      title: getStatusLabel('in_progress'),
      link: '/bureau/reports?status=in_progress',
      icon: {
        key: 'directions_run',
      },
    },
    {
      title: getStatusLabel('rejected'),
      link: '/bureau/reports?status=rejected',
      icon: {
        key: 'error',
      },
    },
  ];

  protected regulierBureau: SidemenuItem[] = [
    {
      title: getStatusLabel('planning_organization'),
      link: '/bureau/reports?status=planning_organization',
      icon: {
        key: 'calendar_today',
      },
    },
    {
      title: getStatusLabel('opname_done'),
      link: '/bureau/reports?status=opname_done',
      icon: {
        key: 'thumb_up',
      },
    },
    {
      title: getStatusLabel('supervisor'),
      link: '/bureau/reports?status=supervisor',
      icon: {
        key: 'how_to_reg',
      },
    },
    {
      title: getStatusLabel('submitted'),
      link: '/bureau/reports?status=submitted',
      icon: {
        key: 'assignment',
      },
    },
    {
      title: getStatusLabel('checking'),
      link: '/bureau/reports?status=checking',
      icon: {
        key: 'find_in_page',
      },
    },
    {
      title: getStatusLabel('veldwerk_rejected'),
      link: '/bureau/reports?status=veldwerk_rejected',
      icon: {
        key: 'error',
      },
    },
  ];

  protected naderAdvies: SidemenuItem[] = [
    {
      title: getStatusLabel('zienswijze_in_progress'),
      link: '/bureau/reports?status=zienswijze_in_progress',
      icon: {
        key: 'directions_run',
      },
    },
    {
      title: getStatusLabel('zienswijze_rejected'),
      link: '/bureau/reports?status=zienswijze_rejected',
      icon: {
        key: 'error',
      },
    },
    {
      title: getStatusLabel('zienswijze_checking'),
      link: '/bureau/reports?status=zienswijze_checking',
      icon: {
        key: 'find_in_page',
      },
    },
    {
      title: getStatusLabel('zienswijze_veldwerk_rejected'),
      link: '/bureau/reports?status=zienswijze_veldwerk_rejected',
      icon: {
        key: 'error',
      },
    },
  ];

  protected bezwaar: SidemenuItem[] = [
    {
      title: getStatusLabel('objection_pending_assignment'),
      link: '/bureau/reports?status=objection_pending_assignment',
      icon: {
        key: 'contact_support',
      },
    },
    {
      title: getStatusLabel('objection_in_progress'),
      link: '/bureau/reports?status=objection_in_progress',
      icon: {
        key: 'directions_run',
      },
    },
    {
      title: getStatusLabel('objection_rejected'),
      link: '/bureau/reports?status=objection_rejected',
      icon: {
        key: 'error',
      },
    },
    {
      title: getStatusLabel('objection_checking'),
      link: '/bureau/reports?status=objection_checking',
      icon: {
        key: 'find_in_page',
      },
    },
    {
      title: getStatusLabel('objection_veldwerk_rejected'),
      link: '/bureau/reports?status=objection_veldwerk_rejected',
      icon: {
        key: 'error',
      },
    },
  ];

  protected itemsException: SidemenuItem[] = [
    {
      title: getStatusLabel('expert_requested'),
      link: '/bureau/reports?status=expert_requested',
      icon: {
        key: 'live_help',
      },
    },
    {
      title: getStatusLabel('aborted'),
      link: '/bureau/reports?status=aborted',
      icon: {
        key: 'cancel',
      },
    },
  ];

  protected planningBureau: SidemenuItem[] = [
    {
      title: getStatusLabel('vervolgopname'),
      link: '/bureau/reports?planning_status=vervolgopname',
      icon: {
        key: 'youtube_searched_for',
      },
    },
    {
      title: getStatusLabel('aanvullend_onderzoek'),
      link: '/bureau/reports?planning_status=aanvullend_onderzoek',
      icon: {
        key: 'zoom_in',
      },
    },
    {
      title: getStatusLabel('nader_onderzoek'),
      link: '/bureau/reports?planning_status=nader_onderzoek',
      icon: {
        key: 'zoom_out_map',
      },
    },
    {
      title: getStatusLabel('heropname'),
      link: '/bureau/reports?planning_status=heropname',
      icon: {
        key: 'zoom_out',
      },
    },
  ];

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.emitBreadcrumb();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Rapportage' }],
    });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
